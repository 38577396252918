var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-client-job-site-create"},[_c('header-view',{attrs:{"title":"New Job Site","closeAction":function () {
        _vm.$router.go(-1);
      },"previous_page":'/clients/view/' + _vm.uuid + '?tab=jobSites',"btnOptions":[
      {
        name: 'Save',
        action: _vm.handleSubmit,
        btnColor: 'button-primary',
      } ]}}),(_vm.jobsite)?_c('main-container',[_c('v-form',{ref:"form",staticClass:"mt-2",attrs:{"id":"jobSiteForm"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('v-container',[_c('v-text-field',{attrs:{"id":"job_site_name","label":"Job Site Name"},model:{value:(_vm.jobsite.name),callback:function ($$v) {_vm.$set(_vm.jobsite, "name", $$v)},expression:"jobsite.name"}}),_c('address-validation-map',{attrs:{"rules":_vm.validate_address},on:{"jobSiteValidated":_vm.updateNewJobSite}}),_c('simple-dialog-template',{attrs:{"open":_vm.errorDialog,"dialogTitle":"Error Creating New Job Site","dialogText":_vm.errorMessage,"dialogButtonOne":"OK"},on:{"buttonOne":function($event){_vm.errorDialog = false}}})],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }