<!-- ----------------------------------------------------------------------- -->
<!--
name    : APP CLIENT JOB SITE CREATE

type    : view

uses    : header-view
          main-container
          simple-dialog-template
          address-validation-map
          main-card

route   : /clients/view/:uuid/newJobSite
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <div class="app-client-job-site-create">
    <header-view
      title="New Job Site"
      :closeAction="
        () => {
          $router.go(-1);
        }
      "
      :previous_page="'/clients/view/' + uuid + '?tab=jobSites'"
      :btnOptions="[
        {
          name: 'Save',
          action: handleSubmit,
          btnColor: 'button-primary',
        },
      ]"></header-view>
    <main-container v-if="jobsite">
      <v-form
        ref="form"
        @submit.prevent="handleSubmit"
        class="mt-2"
        id="jobSiteForm">
        <v-container>
          <v-text-field
            id="job_site_name"
            v-model="jobsite.name"
            label="Job Site Name" />
          <!-- address search and display map -->
          <!-- since this will always be a new jobsite search, addressSearch prop is not used -->
          <address-validation-map
            v-on:jobSiteValidated="updateNewJobSite"
            :rules="validate_address" />
          <simple-dialog-template
            :open="errorDialog"
            dialogTitle="Error Creating New Job Site"
            :dialogText="errorMessage"
            dialogButtonOne="OK"
            @buttonOne="errorDialog = false" />
        </v-container>
      </v-form>
    </main-container>
  </div>
</template>

<script>
  // components
  import MainContainer from '@/components/main-container';
  import MainCard from '@/components/main-card';
  import HeaderView from '@/components/header-view';
  import SimpleDialogTemplate from '@/components/simple-dialog-template';
  import AddressValidationMap from '@/components/address-validation-map';

  // mixins
  import Forms from '@/mixins/forms.js';

  // services
  import Clients from '@/services/Clients.service.js';

  export default {
    name: 'AppClientJobSiteCreate',
    components: {
      'simple-dialog-template': SimpleDialogTemplate,
      'address-validation-map': AddressValidationMap,
      'header-view': HeaderView,
      'main-container': MainContainer,
      // eslint-disable-next-line vue/no-unused-components
      'main-card': MainCard,
    },
    mixins: [Forms],
    props: {
      uuid: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        errorMessage: '',
        errorDialog: false,
        loader: false,
        loading4: false,
        jobsite: null,
        submitted: false,
      };
    },
    watch: {
      loader() {
        const l = this.loader;
        this[l] = !this[l];

        setTimeout(() => (this[l] = false), 3000);

        this.loader = null;
      },
    },
    created() {},
    mounted() {
      if (!this.uuid) {
        console.log('AppClientJobSiteCreate: no uuid was passed in!');
      }

      // create new jobsite object
      this.jobsite = Clients.blankJobSite();
      this.jobsite.client_uuid = this.uuid;
      this.jobsite.tenant_uuid = this.$auth.userProfile.tenant_uuid;
    },
    methods: {
      updateNewJobSite: function (newAddress) {
        if (newAddress) {
          this.jobsite.address = newAddress.address;
          this.jobsite.city = newAddress.city;
          this.jobsite.state = newAddress.state;
          this.jobsite.zip_code = newAddress.zip_code;
          this.jobsite.country = newAddress.country;
        }
      },
      async handleSubmit() {
        // validate form
        if (!this.$refs.form.validate()) {
          return false;
        }

        // make sure none of the job site requirements are empty
        if (
          !(
            this.jobsite.address &&
            this.jobsite.city &&
            this.jobsite.state &&
            this.jobsite.zip_code &&
            this.jobsite.country
          )
        ) {
          this.errorMessage = 'Job Site Address Not Valid';
          this.errorDialog = true;
          return;
        }

        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        // send request to the api service
        const res = await Clients.createJobSite(this.jobsite, accessToken);

        // route back to client job site view or print error
        if (res) {
          this.$router.push('/clients/view/' + this.uuid + '?tab=jobSites');
        } else {
          this.errorMessage = 'Error creating new job site';
          // TODO: fix this use of $nextTick. It's probably a mistake
          // since it doesn't have a callback.
          // eslint-disable-next-line vue/valid-next-tick
          this.$nextTick();
        }
      },
    },
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only
<style scoped lang='scss'>
h3 {
    margin: 40px 0 0;
}
ul {
    list-style-type: none;
    padding: 0;
}
li {
    display: inline-block;
    margin: 0 10px;
}
a {
    color: #42b983;
}

.custom-loader {
    animation: loader 1s infinite;
    display: flex;
}
@-moz-keyframes loader {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}
@-webkit-keyframes loader {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}
@-o-keyframes loader {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}
@keyframes loader {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}
</style>
 -->
