<template>
  <v-card class="px-4 my-3" :min-height="min_height">
    <slot />
  </v-card>
</template>
<script>
  export default {
    name: 'MainCard',
    props: {
      min_height: {
        default: 0,
        type: Number,
      },
    },
    data() {
      return {};
    },
  };
</script>
<style></style>
