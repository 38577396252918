<!-- ----------------------------------------------------------------------- -->
<!--
name    : SIMPLE DIALOG TEMPLATE

type    : component

used by : inventory-map
          AppClientCreate
          work-task-list
          AppCrewEdit
          schedule-dialog
          inner-work-order-list
          work-task
          AppClientJobSiteCreate
          AppEstimateProposal
          AppEstimateView
          AppJobSiteEdit
          AppServiceRequestCreate
          AppServiceRequestView
          AppWorkorderView

uses    : none

route   : none
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <v-dialog
    class="simple-dialog-template"
    v-model="opendialog"
    width="500"
    persistent>
    <v-card color="grey lighten-4" min-width="350px" flat>
      <v-toolbar color="info" flat dark>
        <v-toolbar-title>{{ dialogTitle }}</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        {{ dialogText }}
        <slot />
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if="dialogButtonOne"
          color="button-primary darken-1"
          @click="buttonOneClicked">
          {{ dialogButtonOne }}
        </v-btn>
        <v-btn
          v-if="dialogButtonTwo"
          color="button-secondary"
          @click="buttonTwoClicked">
          {{ dialogButtonTwo }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'SimpleDialogTemplate',
    props: {
      open: {
        type: Boolean,
        default: false,
      },
      dialogTitle: {
        type: String,
        default: 'Please Confirm',
      },
      dialogButtonOne: {
        type: String,
      },
      dialogButtonTwo: {
        type: String,
      },
      dialogText: {
        type: String,
      },
    },
    data() {
      return {
        opendialog: false,
      };
    },
    watch: {
      open: function () {
        // console.log( "hello" )
        this.opendialog = this.open;
      },
    },
    methods: {
      buttonOneClicked: function () {
        // console.log( "buttonOneClicked " )

        this.$emit('buttonOne');
      },
      buttonTwoClicked: function () {
        // console.log( "buttonTwoClicked " )

        this.$emit('buttonTwo');
      },
    },
  };
</script>
